import React from "react";
import { useTranslation } from "react-i18next";
import Page from "components/page/Page";
import Button from "components/button/Button";
import MarkDown from "components/markdown/MarkDown";
import "./assets/styles/ProgramInformationPage.scss";

const ProgramInformationPage = ({
  careProgram,
  onBackButtonClick,
  onSubmit,
}) => {
  // translation helper.
  const { t } = useTranslation();
  const title = t("corona.page.program.information.title", {
    name: t(careProgram.title),
  });

  return (
    <Page
      className={"p-program-information-page"}
      title={title}
      onBackButtonClick={onBackButtonClick}
      data-test-id={`program-info-page-${careProgram.id}`}
    >
      {careProgram.information.image && (
        <div className={"p-program-information-page__image"}>
          <img src={careProgram.information.image} alt={title} title={title} />
        </div>
      )}
      <MarkDown>{t(careProgram.information.content)}</MarkDown>
      <div className={"p-program-information-page__footer"}>
        <Button
          role={"primary"}
          onClick={onSubmit}
          data-test-id="program-information-next"
        >
          {t("corona.general.button.next.title")}
        </Button>
      </div>
    </Page>
  );
};

export default ProgramInformationPage;
