import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Page from "components/page/Page";
import List from "components/list/List";
import ListItem from "components/list/ListItem";
import ListItemIcon from "components/list/ListItemIcon";
import ListItemText from "components/list/ListItemText";
import ListItemButton from "components/list/ListItemButton";
import "./assets/styles/CareProvidersPage.scss";

/**
 * Care program selection page component
 * @param {array} programs
 * @param {function} onBackButtonClick
 * @param {function} onSubmit
 * @return {*}
 * @constructor
 */
const CareProgramPage = ({ programs, onBackButtonClick, onSubmit }) => {
  // translation helper.
  const { t } = useTranslation();

  return (
    <Page
      className={"p-care-program"}
      title={t("corona.page.care.program.title")}
      noContentPadding={true}
      onBackButtonClick={onBackButtonClick}
    >
      <List>
        {programs &&
          programs.map((item) => (
            <ListItem key={item.id} onClick={() => onSubmit(item)}>
              <ListItemIcon>
                <img
                  src={item.image}
                  alt={item.title}
                  title={""}
                  className={"item-image"}
                />
              </ListItemIcon>
              <ListItemText
                primary={t(item.title)}
                secondary={t(item.subtitle)}
              />
              <ListItemButton
                data-test-id={`program-button-${item.id}`}
                role={"primary"}
              >
                Start
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Page>
  );
};

CareProgramPage.prototype = {
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  onBackButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CareProgramPage;
