import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/Button';
import BackIcon from 'components/icon/BackIcon';
import './assets/styles/BackButton.scss';

/**
 * Back button component
 *
 * @param {function} onClick
 * @return {*}
 * @constructor
 */
const BackButton = ({onClick}) => (<Button className={'c-back-button'} onClick={onClick}><BackIcon/></Button>);

BackButton.propTypes = {
  onClick: PropTypes.func,
};

export default BackButton;
