import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './assets/styles/ListItemText.scss'

/**
 * List item text component
 *
 * @param {string} className
 * @param {string|*} primary
 * @param {string|*} secondary
 * @param {*} children
 * @return {*}
 * @constructor
 */
const ListItemText = ({className, primary, secondary, children}) => (
  <div className={classNames('c-list-item-text', className)}>
    {primary && <div className={'c-list-item-text__primary'}>{primary}</div>}
    {secondary && <div className={'c-list-item-text__secondary'}>{secondary}</div>}
    {children}
  </div>
);

ListItemText.propTypes = {
  className: PropTypes.string,
  primary  : PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children : PropTypes.node,
};

export default ListItemText;
