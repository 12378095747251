import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Page from 'components/page/Page';
import PageAnimation from 'assets/lottie/onboard-house';
import InputNumber from 'components/input/InputNumber';
import './assets/styles/PostalCodePage.scss'

/**
 * Postal code page component
 *
 * @param {object} country
 * @param {boolean} showBackButton
 * @param {function} onSubmit
 * @param {function} onBackButtonClick
 * @return {*}
 * @constructor
 */
const PostalCodePage = (
  {
    country,
    showBackButton = true,
    onSubmit,
    onBackButtonClick,
  }) => {
  // translation helper.
  const {t}                         = useTranslation();
  const inputSize                   = country.postalCodeSize || 4;
  const [inputValue, setInputValue] = useState('');

  return (
    <Page
      className={'p-postal-code'}
      onBackButtonClick={onBackButtonClick}
      showBackButton={showBackButton}
      image={PageAnimation}
      title={t('corona.page.postal.code.title')}
    >
      <p>
        {t('corona.page.postal.code.description')}
      </p>
      <InputNumber
        data-test-id="postal-code-input"
        focus={true}
        className={'p-postal-code__input'}
        type={'number'}
        pattern="\d*"
        name={'postalcode'}
        inputMode={'numeric'}
        value={inputValue}
        placeholder={Array(inputSize).fill('0').join('')}
        maxLength={inputSize}
        onChange={(evt) => {
          const {value} = evt.target;
          if (value.length <= inputSize) {
            setInputValue(value);
            if (value.length === inputSize) {
              onSubmit(value);
            }
          }
        }}
      />
    </Page>
  );
};

PostalCodePage.propTypes = {
  country          : PropTypes.shape({
    postalCodeSize: PropTypes.number,
  }),
  showBackButton   : PropTypes.bool,
  onSubmit         : PropTypes.func,
  onBackButtonClick: PropTypes.func,
};

export default PostalCodePage;
