import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/Box.scss';

/**
 * Box component
 *
 * @param {string} className
 * @param {string} appearance
 * @param {*} children
 * @return {*}
 * @constructor
 */
const Box = ({className, appearance, children}) => (<div
  className={classNames('c-box', 'c-box__appearance-' + appearance, className)}>
  {children}
</div>);

// appearance types
Box.APPEARANCE_DEFAULT = 'default';
Box.APPEARANCE_RED     = 'red';

Box.propTypes = {
  className : PropTypes.string,
  appearance: PropTypes.oneOf([Box.APPEARANCE_DEFAULT, Box.APPEARANCE_RED]),
  children  : PropTypes.node,
};

export default Box;
