import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/FormGroup.scss';

/**
 * Form group component
 *
 * @param {string} className
 * @param {string} label
 * @param {string} error
 * @param {*} children
 * @return {*}
 * @constructor
 */
const FormGroup = (
  {
    className,
    label,
    error,
    children,
  }) => (
  <div className={classNames('c-form-group', className, {'c-form-group__has-error': (!!error)})}>
    {label && (<label className={'c-form-group__label'}>{label}</label>)}
    {children}
    {error && (<div className={'c-form-group__error'}>{error}</div>)}
  </div>
);

FormGroup.propTypes = {
  className: PropTypes.string,
  label    : PropTypes.string,
  error    : PropTypes.string,
  children : PropTypes.node,
};

export default FormGroup;
