/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import FormGroup from "components/formgroup/FormGroup";
import Input from "components/input/Input";
import Select from "components/select/Select";
import RadioGroup from "components/radio/RadioGroup";
import Checkbox from "components/checkbox/Checkbox";
import Textarea from "components/textarea/Textarea";
import Button from "components/button/Button";
import Box from "components/box/Box";
import {
  validateEmail,
  validateNumber,
  validatePhoneNumber,
  validateRequired,
} from "utils/validators";
import "./assets/styles/Form.scss";

/**
 * Form
 *
 * @param {array} values
 * @param {array} questions
 * @param {function} onSubmit
 * @param {string} submitLabel
 * @returns {*}
 * @constructor
 */
const Form = ({
  values,
  questions,
  onSubmit,
  submitLabel = "corona.signup.button",
}) => {
  const [formData, setFormData] = useState(values);
  const [formErrors, setFormErrors] = useState({});
  const { t } = useTranslation();

  // setup default values in the question in the formData.
  useEffect(() => {
    const newFormData = { ...formData };
    const defaultValues = questions.filter(
      (item) => item.defaultValue !== undefined
    );
    defaultValues.forEach((item) => {
      const { name, defaultValue } = item;
      newFormData[name] = defaultValue;
    });
    setFormData(newFormData);
  }, [questions]);

  // region form data functions
  /**
   * Set value in the formData state.
   *
   * @param {string} key
   * @param {string} value
   */
  const setFormValue = (key, value) => {
    if (formErrors && formErrors[key]) {
      let nextErrors = { ...formErrors };
      delete nextErrors[key];
      setFormErrors(nextErrors);
    }

    setFormData((data) => {
      return {
        ...data,
        [key]: value,
      };
    });
  };

  /**
   * Get value from the formData state or the default value if it is specified
   * @param {string} key
   * @param {*} defaultValue
   * @return {string|undefined}
   */
  const getFormValue = (key, defaultValue = undefined) => {
    return formData[key] || defaultValue;
  };

  /**
   * Shortcut function for handling input events
   *
   * @param {string} key
   * @param {Event} evt
   */
  const onFormChange = (key, evt) => setFormValue(key, evt.target.value);
  // endregion

  // region error handling functions
  /**
   * Get error of specific field
   *
   * @param {string} key
   * @return {string|undefined}
   */
  const getError = (key) => {
    return formErrors[key] || undefined;
  };

  /**
   * Check if a specific field has a error
   *
   * @param {string} key
   * @return {boolean}
   */
  const hasError = (key) => {
    return !!formErrors[key];
  };

  /**
   * Check if the form has any errors
   *
   * @return {boolean}
   */
  const hasAnyErrors = () => {
    return Object.keys(formErrors).length > 0;
  };
  // endregion

  /**
   * Check if the given question is excluded by a whitelist/blacklist
   *
   * @param question
   * @returns {boolean}
   */
  const isQuestionExcluded = (question) => {
    if (question) {
      const programId = values.careProgram ? values.careProgram.id : undefined;

      if (question.whitelist) {
        const { programs } = question.whitelist;
        if (programs && programs.findIndex((p) => p === programId) <= -1) {
          return true;
        }
      } else if (question.blacklist) {
        const { programs } = question.blacklist;
        if (programs && programs.findIndex((p) => p === programId) > -1) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Validate form
   */
  const validateFormData = () => {
    let errors = {};
    let hasErrors = false;

    questions.forEach((question) => {
      if (isQuestionExcluded(question)) return;

      const { name } = question;
      const answer = getFormValue(name, "");

      // when validators are given check the answer against each validator.
      if (question.validators) {
        question.validators.forEach((validatorType) => {
          let validationCallback;

          if (validatorType === "email") {
            validationCallback = validateEmail;
          }
          if (validatorType === "number") {
            validationCallback = validateNumber;
          }
          if (validatorType === "phoneNumber") {
            validationCallback = validatePhoneNumber;
          }
          if (validatorType === "required") {
            validationCallback = validateRequired;
          }

          // when a validation callback was found validate the answer against
          // the given validator.
          if (validationCallback && !validationCallback(answer)) {
            hasErrors = true;
            errors[name] = t("corona.error.invalid");
          }
        });
      }
    });

    // check if there are errors. If no errors are found we will call onSubmit function with the data.
    // else we will set state of the error.
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      onSubmit(formData);
    }
  };

  /**
   * Translate radio group options or select options.
   * @param {array|object} options
   * @return {*}
   */
  const translateOptions = (options) => {
    if (options instanceof Array) {
      return options.map((item) => {
        return {
          ...item,
          text: t(item.text),
        };
      });
    }

    if (options instanceof Object) {
      const keys = Object.keys(options);
      const newOptions = { ...options };
      keys.forEach((itemKey) => {
        newOptions[itemKey] = t(options[itemKey]);
      });
      return newOptions;
    }

    return options;
  };

  return (
    <div className={"c-form"}>
      {questions.map((question, questionIndex) => {
        const { name, type, label, defaultValue } = question;
        const labelText = t(label);

        if (isQuestionExcluded(question)) return;

        switch (type) {
          case Form.QUESTION_TYPES.HIDDEN:
            return (
              <Input
                data-test-id={`form-field-${name}`}
                tabIndex={questionIndex + 1}
                type={Input.TYPES.HIDDEN}
                value={t(defaultValue)}
                name={name}
              />
            );
          case Form.QUESTION_TYPES.TEXT:
            return (
              <FormGroup label={labelText} error={getError(name)} key={name}>
                <Input
                  data-test-id={`form-field-${name}`}
                  tabIndex={questionIndex + 1}
                  type={question.inputType || Input.TYPES.TEXT}
                  value={getFormValue(name, defaultValue || "")}
                  name={name}
                  error={hasError(name)}
                  placeholder={t(question.placeholder)}
                  readOnly={question.isReadOnly ?? false}
                  onChange={(evt) => onFormChange(name, evt)}
                />
              </FormGroup>
            );
          case Form.QUESTION_TYPES.DATE:
            return (
              <FormGroup label={labelText} error={getError(name)} key={name}>
                <Input
                  data-test-id={`form-field-${name}`}
                  tabIndex={questionIndex + 1}
                  type={Form.QUESTION_TYPES.DATE}
                  value={getFormValue(name, defaultValue || "")}
                  name={name}
                  error={hasError(name)}
                  placeholder={t(question.placeholder)}
                  onChange={(evt) => onFormChange(name, evt)}
                />
              </FormGroup>
            );
          case Form.QUESTION_TYPES.SELECT:
            return (
              <FormGroup label={labelText} error={getError(name)} key={name}>
                <Select
                  data-test-id={`form-field-${name}`}
                  tabIndex={questionIndex + 1}
                  name={name}
                  value={getFormValue(name, defaultValue)}
                  options={translateOptions(question.options)}
                  error={hasError(name)}
                  onChange={(value) => setFormValue(name, value)}
                />
              </FormGroup>
            );
          case Form.QUESTION_TYPES.RADIO:
            return (
              <FormGroup label={labelText} error={getError(name)} key={name}>
                <RadioGroup
                  data-test-id={`form-field-${name}`}
                  tabIndex={questionIndex + 1}
                  name={name}
                  selectedOption={getFormValue(name, defaultValue)}
                  error={hasError(name)}
                  radioOptions={translateOptions(question.options)}
                  vertical={question.vertical || false}
                  onChange={(evt) => onFormChange(name, evt)}
                />
              </FormGroup>
            );
          case Form.QUESTION_TYPES.LABEL:
            return <p key={name}>{labelText}</p>;
          case Form.QUESTION_TYPES.CHECKBOX:
            return (
              <FormGroup
                error={getError(name)}
                key={name}
                className={"c-form__form-group-checkbox"}
              >
                <Checkbox
                  data-test-id={`form-field-${name}`}
                  tabIndex={questionIndex + 1}
                  name={name}
                  checked={getFormValue(name, false)}
                  value={"1"}
                  text={labelText}
                  onChange={(evt) => setFormValue(name, evt.target.checked)}
                />
              </FormGroup>
            );
          case Form.QUESTION_TYPES.TEXTAREA:
            return (
              <FormGroup label={labelText} error={getError(name)} key={name}>
                <Textarea
                  maxLength="150"
                  data-test-id={`form-field-${name}`}
                  tabIndex={questionIndex + 1}
                  name={name}
                  value={getFormValue(name, defaultValue || "")}
                  error={hasError(name)}
                  placeholder={question.placeholder}
                  onChange={(evt) => onFormChange(name, evt)}
                />
              </FormGroup>
            );
          case Form.QUESTION_TYPES.LEGAL:
            return (
              <FormGroup error={getError(name)} key={name}>
                <Box
                  appearance={Box.APPEARANCE_DEFAULT}
                  className={"c-form__legal-box"}
                >
                  <h2>{t(labelText)}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: t(question.content) }}
                  />
                  {question.checkboxText && (
                    <div>
                      <Checkbox
                        data-test-id={`form-field-${name}`}
                        tabIndex={questionIndex + 1}
                        name={name}
                        checked={getFormValue(name, false)}
                        value={"1"}
                        text={t(question.checkboxText)}
                        onChange={(evt) =>
                          setFormValue(name, evt.target.checked)
                        }
                      />
                    </div>
                  )}
                </Box>
              </FormGroup>
            );
        }

        return (
          <div key={name}>Element with type: {type} is not supported!</div>
        );
      })}
      {hasAnyErrors() && (
        <Box appearance={Box.APPEARANCE_RED} className={"c-form__error-box"}>
          <h2>{t("corona.error.generic")}</h2>
          <div>{t("corona.error.generic.message")}</div>
        </Box>
      )}
      <div className={"c-form__footer"}>
        <Button
          data-test-id={`form-field-next-button`}
          tabIndex={questions.length + 1}
          role={"primary"}
          onClick={() => validateFormData()}
        >
          {t(submitLabel)}
        </Button>
      </div>
    </div>
  );
};

// region component constants and prop types.
Form.QUESTION_TYPES = {
  HIDDEN: "hidden",
  TEXT: "text",
  DATE: "date",
  SELECT: "select",
  RADIO: "radio",
  LABEL: "label",
  CHECKBOX: "checkbox",
  TEXTAREA: "textarea",
  LEGAL: "legal",
};

Form.propTypes = {
  values: PropTypes.object,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([
        Form.QUESTION_TYPES.HIDDEN,
        Form.QUESTION_TYPES.TEXT,
        Form.QUESTION_TYPES.SELECT,
        Form.QUESTION_TYPES.RADIO,
        Form.QUESTION_TYPES.LABEL,
        Form.QUESTION_TYPES.CHECKBOX,
        Form.QUESTION_TYPES.TEXTAREA,
        Form.QUESTION_TYPES.LEGAL,
        Form.QUESTION_TYPES.DATE,
      ]).isRequired,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      whitelist: PropTypes.shape({
        programs: PropTypes.arrayOf(PropTypes.string),
      }),
      blacklist: PropTypes.shape({
        programs: PropTypes.arrayOf(PropTypes.string),
      }),
    })
  ),
  onBackButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
};
// endregion

// region component default values.
Form.defaultProps = {
  questions: [],
};
// endregion

export default Form;
