import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/Button';
import './assets/styles/ListItemButton.scss';

/**
 * List item button component
 *
 * @param {*} children
 * @param {*} props
 * @return {*}
 * @constructor
 */
const ListItemButton = ({children, ...props}) => (
  <div className={'c-list-item-button'}>
    <Button {...props}>{children}</Button>
  </div>
);

ListItemButton.propTypes = {
  children: PropTypes.node,
};

export default ListItemButton;
