import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/List.scss';

/**
 * List component
 *
 * @param {string} className
 * @param {*} children
 * @return {*}
 * @constructor
 */
const List = ({className, children}) => (
  <nav className={classNames('c-list', className)}>
    {children}
  </nav>
);

List.propTypes = {
  className: PropTypes.string,
  children : PropTypes.node,
};

export default List;