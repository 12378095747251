import React from "react";
import Proptypes from "prop-types";
import classNames from "classnames";
import "./assets/styles/Title.scss";

/**
 * Represents a single title in the Luscii web application.
 *
 * Usage:
 *
 * ```
 * <Title type={Title.TYPES.BIG}>
 *   Awesome big title text here.
 * </Title>
 * ```
 *
 * @param {string} className
 * @param {string} type (Defaults to Title.TYPES.DEFAULT)
 * @param {node} children
 * @param {object} props (All other properties will be spread and passed to the component)
 * @return {*}
 * @constructor
 */
const Title = (
  {
    className,
    type = Title.TYPES.DEFAULT,
    ...props
  }
) => {
  const tags    = {
    [Title.TYPES.DEFAULT]: 'h1',
    [Title.TYPES.BIG]    : 'h1',
    [Title.TYPES.SMALL]  : 'h3',
    [Title.TYPES.TINY]   : 'h3',
    [Title.TYPES.BOLD]   : 'h1',
  };
  const Element = tags[type];

  return (
    <Element
      className={classNames('c-title', className, {
        "c-title__type-default": type === Title.TYPES.DEFAULT,
        "c-title__type-big"    : type === Title.TYPES.BIG,
        "c-title__type-small"  : type === Title.TYPES.SMALL,
        "c-title__type-tiny"   : type === Title.TYPES.TINY,
        "c-title__type-bold"   : type === Title.TYPES.BOLD
      })}
      {...props}
    />
  );
};

// region component constants used for configuration.
Title.TYPES = {
  DEFAULT: "default",
  BIG    : "big",
  BOLD   : "bold",
  SMALL  : "small",
  TINY   : "tiny"
};
// endregion

// region component property configuration.
Title.propTypes = {
  type     : Proptypes.oneOf([
    Title.TYPES.DEFAULT,
    Title.TYPES.BIG,
    Title.TYPES.SMALL,
    Title.TYPES.TINY,
    Title.TYPES.BOLD,
  ]).isRequired,
  className: Proptypes.string,
  children : Proptypes.node.isRequired,
};
// endregion

// region component property defaults.
Title.defaultProps = {
  type: Title.TYPES.DEFAULT,
};
// endregion

export default Title;
