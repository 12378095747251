import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Radio from './Radio';
import './assets/styles/RadioGroup.scss';

/**
 * Radio group component
 *
 * @param {string} className
 * @param {string} radioClassName
 * @param {string} name
 * @param {string} selectedOption
 * @param {boolean} vertical
 * @param {array} radioOptions
 * @param {boolean} error
 * @param {function} onChange
 * @return {*}
 * @constructor
 */
const RadioGroup = (
  {
    className,
    radioClassName,
    name,
    selectedOption,
    vertical,
    radioOptions,
    error,
    onChange,
    ...otherProps
  }) => {
  const containerClassName = classNames('c-radio-group', {'vertical': vertical, 'hasError': error}, className);

  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={containerClassName} {...otherProps}>
      {radioOptions.map((option, key) => (
        <Radio
          data-test-id={option.value}
          key={option.value}
          name={name}
          className={radioClassName}
          checked={selectedOption === option.value}
          text={option.text}
          disabled={option.disabled}
          value={option.value}
          onChange={handleChange}
        />
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  className     : PropTypes.string,
  radioClassName: PropTypes.string,
  name          : PropTypes.string,
  selectedOption: PropTypes.string,
  vertical      : PropTypes.bool,
  radioOptions  : PropTypes.arrayOf(PropTypes.shape({
    text    : PropTypes.string,
    value   : PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  onChange      : PropTypes.func
};

export default RadioGroup;
