import * as qs from "qs";
import 'whatwg-fetch';

const API_SERVICE_URL = '/api';
const API_LUSCII_URL = process.env.REACT_APP_API_LUSCII_URL || 'https://vitalsapi.luscii.com/api/'
const API_LUSCII_CLIENT_ID = process.env.REACT_APP_API_LUSCII_CLIENT_ID || 'acbe75d3169979ed37d8c8199f335a26ae2f144c';
const API_LUSCII_CLIENT_SECRET = process.env.REACT_APP_API_LUSCII_CLIENT_SECRET || '323114a7742d2fda3343aecc33e82601db991213';
const API_ONBOARD_CHECK_USERNAME_AVAILABLE_URL = `${API_LUSCII_URL}users/accounts`;
const API_ONBOARD_REGISTER_USER_URL = `${API_LUSCII_URL}patients/onboarding`;

class Api {

  // cache properties to get results that needs to be cached.
  // pure for internal use.
  _cache;

  constructor() {
    this._cache = {
      postalCodes: [],
      countries: [],
      careProviders: [],
    };
  }

  /**
   * Perform a fetch request with the given method to the given url, body and headers
   * can be attached optionally.
   * Note: header content-type will by default by overwritten with application/json.
   * @param {string} method
   * @param {string} url
   * @param {object} body
   * @param {object} headers (Optional)
   * @returns {Promise<unknown>}
   */
  request(method, url, body, headers = {}) {
    return new Promise((resolve, reject) => {
      const config = {
        redirect: 'follow',
        method: method,
      };

      if (body) {
        config['body'] = JSON.stringify(body);
      }

      config['headers'] = new Headers(headers || {});
      config['headers'].append('Content-Type', 'application/json');
      config['headers'].append('X-Client-Id', API_LUSCII_CLIENT_ID);
      config['headers'].append('X-Client-Secret', API_LUSCII_CLIENT_SECRET);
      config['mode'] = 'cors';

      fetch(url, config)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  };

  /**
   * Register a new user within the luscii api.
   * @param {object} user
   * @returns {Promise<unknown>}
   */
  registerUser(user) {
    return this.request(
      'POST', API_ONBOARD_REGISTER_USER_URL, user
    );
  }

  /**
   * Grab Countries
   *
   * @return {Promise<array>}
   */
  getCountries() {
    return new Promise((resolve, reject) => {
      fetch(API_SERVICE_URL + '/countries.json')
        .then((response) => response.json())
        .then((json) => {
          this._cache.countries = json;
          resolve(json);
        })
        .catch(() => reject());
    });
  }

  /**
   * Get a specific country
   *
   * @param {string} countryCode
   * @return {object|undefined}
   */
  getCountry(countryCode) {
    return new Promise((resolve, reject) => {
      this.getCountries()
        .then(
          countries => {
            resolve(countries.find((item) => (item.id === countryCode)));
          })
        .catch(reject);
    });
  }

  /**
   * Get all care providers per country code.
   * @param {string} countryCode
   * @returns {Promise<unknown>}
   */
  getCareProvidersByCountryCode(countryCode) {
    return new Promise((resolve, reject) => {
      fetch(API_SERVICE_URL + '/' + countryCode + '/careproviders.json')
        .then((response) => response.json())
        .then((json) => {
          this._cache.careProviders = json;
          resolve(json);
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * Get care providers for specific country and postalcode
   *
   * @param {string} countryCode
   * @param {string} postalCode
   * @return {Promise<array>}
   */
  getCareProviders(countryCode, postalCode = undefined) {
    return new Promise((resolve, reject) => {
      this.getCareProvidersByCountryCode(countryCode)
        .then(
          (careProviders) => {
            const filteredCareProviders = careProviders.filter(careProvider => {
              // when no postal code was given or the care provider doesn't have postal codes configured always
              // add it to the filtering.
              if (postalCode === undefined || (careProvider.postalCodes && careProvider.postalCodes.length === 0)) {
                return true;
              }

              if (careProvider.postalCodes && careProvider.postalCodes.length > 0) {
                // if postal codes are given let's filter care providers.
                const postalCodeNumber = parseInt(postalCode);
                const filteredPostalCodes = careProvider.postalCodes.filter(postCodesEntry => {
                  return (postalCodeNumber >= postCodesEntry.min) && (postalCodeNumber <= postCodesEntry.max);
                });

                return (filteredPostalCodes.length > 0);
              }

              return true;
            });

            resolve(filteredCareProviders);
          }
        )
        .catch(error => reject(error));
    });
  }

  /**
   * Get programs from care provider
   *
   * @param {string} country
   * @param {string} careProviderId
   * @return {Promise<array>}
   */
  getPrograms(country, careProviderId) {
    return new Promise((resolve) => {
      if (this._cache.careProviders) {
        const careProvider = this._cache.careProviders.filter((item) => (item.id === careProviderId));
        if (careProvider.length === 1) {
          resolve(careProvider[0].programs);
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    });
  }

  /**
   * Get care provider question for a specific program
   *
   * @param {string} countryCode
   * @param {string} careProviderId
   * @param {string} careProgramId
   * @return {Promise<array>}
   */
  getCareProviderQuestions(countryCode, careProviderId, careProgramId) {
    return new Promise((resolve, reject) => {
      this.getCareProvidersByCountryCode(countryCode)
        .then(
          (careProviders) => {
            const careProvider = careProviders.find(careProvider => careProvider.id === careProviderId);
            if (!careProvider) {
              reject();
            } else {
              const program = careProvider.programs.find(program => program.id === careProgramId);
              if (!program) {
                reject();
              } else {
                resolve(program.questions || []);
              }
            }
          })
        .catch(error => reject(error));
    });
  }

  getIsAccountNameAvailable(email, accountName = undefined) {
    const params = qs.stringify({ accountName: accountName || email });
    return new Promise((resolve, reject) => {
      this.request('GET', `${API_ONBOARD_CHECK_USERNAME_AVAILABLE_URL}?${params}`)
        .then(response => {
          switch (response.status) {
            case 404:
              return resolve(true);

            case 429:
              throw new Error("Too Many Requests");

            default:
              return resolve(false);
          }
        })
        .catch(error => reject(error))
    });
  }

  /**
   * Get all personal detail questions, these are configured per country.
   * @param {string} countryCode   * @returns {Promise<unknown>}
   */
  getCountryQuestions(countryCode) {
    return new Promise((resolve, reject) => {
      this.getCountry(countryCode).then(
        country => {
          resolve((country && country.questions) || [])
        }
      );
    });
  }
}

const api = new Api();
export default api;
