import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Title from 'components/title/Title';
import BackButton from 'components/backbutton/BackButton';
import Animation from 'components/animation/Animation';
import './assets/styles/Page.scss';

/**
 * Check if the image is lottie file and create correct component.
 *
 * @param {string|Object} imageData
 * @param {string} alt
 * @return {*}
 * @constructor
 */
const ImageParse = ({imageData, alt}) => {
  // if image is lottie file
  if (imageData instanceof Object) {
    return (
      <Animation
        animationData={imageData}
        width={'100%'}
        height={'100%'}
        loop={false}
      />
    );
  }
  // fallback to img
  return (<img src={imageData} alt={alt} title={''}/>);
};

/**
 * Page component
 *
 * @param {string} className
 * @param {string} title
 * @param {boolean} titleFullWidth
 * @param {string} image
 * @param {boolean} showBackButton
 * @param {boolean} noContentPadding
 * @param {boolean} modal
 * @param {function} onBackButtonClick
 * @param {*} children
 * @return {*}
 * @constructor
 */
const Page = (
  {
    className,
    title,
    titleFullWidth = true,
    image,
    showBackButton = true,
    noContentPadding = false,
    modal = false,
    onBackButtonClick,
    children,
    ...otherProps
  }) => (
  <div className={classNames('c-page', className, {
    'c-page--no-content-padding': noContentPadding,
    'c-page--modal'             : modal,
  })} {...otherProps}>
    <div className={'c-page__back-button'}>
      {showBackButton && <BackButton onClick={onBackButtonClick}/>}
    </div>
    <div className={'c-page__container'}>
      <div className={'c-page__header'}>
        {image && (
          <div className={'c-page__header__image'}>
            <div className={'inner'}>
              <ImageParse imageData={image} alt={title}/>
            </div>
          </div>
        )}
        {title && (
          <div className={'c-page__header__title'}>
            <Title className={classNames({'c-title__full-width': titleFullWidth})}>{title}</Title>
          </div>
        )}
      </div>
      <div className={'c-page__content'}>
        {children}
      </div>
    </div>
  </div>
);

Page.propTypes = {
  className        : PropTypes.string,
  title            : PropTypes.string,
  image            : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showBackButton   : PropTypes.bool,
  noContentPadding : PropTypes.bool,
  modal            : PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  children         : PropTypes.node,
};
export default Page;
