import React from 'react';
import PropTypes from 'prop-types';

/**
 * List item icon component
 *
 * @param {*} children
 * @return {*}
 * @constructor
 */
const ListItemIcon = ({children}) => (
  <div className={'c-list-item-icon'}>
    {children}
  </div>
);

ListItemIcon.propTypes = {
  children : PropTypes.node,
};

export default ListItemIcon;