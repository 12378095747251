import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

/**
 * Markdown wrapper component
 *
 * @param {*} children
 * @return {*}
 * @constructor
 */
const MarkDown = ({children}) => (
  <div className={'c-mark-down'}>
    <ReactMarkdown>{children}</ReactMarkdown>
  </div>
);

MarkDown.propTypes = {
  children: PropTypes.node,
};

export default MarkDown;
