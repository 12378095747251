import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/Radio.scss';

/**
 * Radio component
 *
 * @param {string} className
 * @param {string} checked
 * @param {boolean} disabled
 * @param {string} name
 * @param {string} value
 * @param {string} text
 * @param {function} onChange
 * @param {object} props
 * @return {*}
 * @constructor
 */
const Radio = (
  {
    className,
    checked,
    disabled,
    name,
    value,
    text,
    onChange,
    ...props
  }) => {
  const [focused, setFocused] = useState(false);
  const id = Math.random();

  return (
    <div
      className={classNames('c-radio', className, {
        'is-focused' : focused,
        'is-checked' : checked,
        'is-disabled': disabled,
      })}
    >
      <input
        className="c-radio__input"
        id={id}
        name={name}
        type="radio"
        checked={checked}
        disabled={disabled}
        value={value}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={(evt) => {
          if (onChange) {
            onChange(evt);
          }
        }}
        {...props}
      />
      <label className="c-radio__label" htmlFor={id}>
        {text &&<span className="c-radio__label-text">{text}</span>}
      </label>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  text     : PropTypes.string,
  checked  : PropTypes.bool.isRequired,
  disabled : PropTypes.bool,
  name     : PropTypes.string.isRequired,
  value    : PropTypes.string,
  onChange : PropTypes.func
};

export default Radio;
