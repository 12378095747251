import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../assets/styles/CodeInput.scss';

const KEY_CODE = {
  BACKSPACE: 8,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  E_KEY: 69
};

const CodeInput = ({autoFocus, error, disabled, className, fields, values, placeholders, onChange, onComplete}) => {
  const [autoFocusIndex, setAutoFocusIndex] = useState(0);
  const [internalValues, setInternalValues] = useState(Array(fields).fill(''));
  const [internalReferences, setInternalReferences] = useState([]);

  // region set up internal references to input
  useEffect(() => {
    let refs = [];
    for (let i = 0; i < fields; i++) {
      refs.push(React.createRef());
    }
    setInternalReferences(refs);
  }, [fields]);
  // endregion

  // region value into values array
  useEffect(() => {
    let nextValues = [];
    if (values !== undefined) {
      for (let i = 0; i < fields; i++) {
        nextValues.push(values[i] !== undefined ? values[i] : '');
      }

      setAutoFocusIndex(values.length >= fields ? 0 : values.length);
    } else {
      setAutoFocusIndex(0);
    }

    setInternalValues(nextValues);
  }, [fields, values]);
  // endregion

  const triggerOnChange = (values = internalValues) => {
    const val = values.join('');
    onChange && onChange(val);
    if (onComplete && val.length >= fields) {
      onComplete(val);
    }
  };

  const handleOnChange = (event) => {
    event.target.value = event.target.value.replace(/[^\d]/gi, '');
    if (event.target.value === '' || !event.target.validity.valid) {
      return;
    }

    const index = parseInt(event.target.dataset.index, 10);

    internalValues[index] = event.target.value.slice(-1);
    setInternalValues(internalValues);

    if (event.target.value && internalReferences[index + 1]) {
      const next = internalReferences[index + 1];

      if (next) {
        next.current.focus();
        next.current.select();
      }
    }

    triggerOnChange(internalValues);
  };

  const handleOnKeyDown = (event) => {
    const index = parseInt(event.target.dataset.index, 10);
    const prev = internalReferences[index - 1];
    const next = internalReferences[index + 1];

    switch (event.keyCode) {
      case KEY_CODE.BACKSPACE:
        event.preventDefault();
        if (internalValues[index]) {
          internalValues[index] = '';
          setInternalValues(internalValues);
          triggerOnChange(internalValues);
        } else if (prev) {
          internalValues[index - 1] = '';
          prev.current.focus();
          setInternalValues(internalValues);
          triggerOnChange(internalValues);
        }
        break;
      case KEY_CODE.LEFT:
        event.preventDefault();
        if (prev) {
          prev.current.focus();
        }
        break;
      case KEY_CODE.RIGHT:
        event.preventDefault();
        if (next) {
          next.current.focus();
        }
        break;
      case KEY_CODE.UP:
      case KEY_CODE.DOWN:
        event.preventDefault();
        break;
      case KEY_CODE.E_KEY:
        // this case needs to be handled because of
        // https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (event.target.type === 'number') {
          event.preventDefault();
          break;
        }
        break;
      default:
        break;
    }
  };

  const handleOnFocus = (event) => {
    event.target.select(event);
  };

  return (
    <div className={classNames('c-code-input', className)}>
      <div>
        {internalValues.map((value, index) => (
          <input className={classNames('c-input', { 'c-input--error': error })}
                 type="tel"
                 pattern="[0-9]*"
                 maxLength={1}
                 min={0}
                 max={9}
                 autoFocus={autoFocus && index === autoFocusIndex}
                 placeholder={placeholders[index] || ''}
                 key={`c-code-input-${index}`}
                 ref={internalReferences[index]}
                 value={value}
                 data-index={index}
                 onChange={handleOnChange}
                 onKeyDown={handleOnKeyDown}
                 onFocus={handleOnFocus}
                 disabled={disabled} />
        ))}
      </div>
    </div>
  )
};

CodeInput.propTypes = {
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  fields: PropTypes.number,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.string),
  placeholders: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

CodeInput.defaultProps = {
  fields: 4,
  autoFocus: true,
  disabled: false,
  placeholders: [],
};

export default CodeInput;
