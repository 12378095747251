import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Page from 'components/page/Page';
import pageAnimation from 'assets/lottie/onboard-heart';
import alertAnimation from 'assets/lottie/onboard-locked';
import FormGroup from 'components/formgroup/FormGroup';
import InputNumber from 'components/input/InputNumber';
import {validateBirthDate, validateBirthDateResult} from 'utils/utils';
import './assets/styles/AgeVerificationPage.scss';

const DATE_FIELD_DAY   = 'date_field_day';
const DATE_FIELD_MONTH = 'date_field_month';
const DATE_FIELD_YEAR  = 'date_field_year';

/**
 * Age verification page component
 *
 * @param {boolean} showBackButton
 * @param {function} onSubmit
 * @param {function} onBackButtonClick
 * @return {*}
 * @constructor
 */
const AgeVerificationPage = (
  {
    showBackButton = true,
    onSubmit,
    onBackButtonClick,
  }) => {
  // translation helper.
  const {t} = useTranslation();

  // state for error and showing underage alert.
  const [isError, setIsError]                     = useState(false);
  const [showUnderageAlert, setShowUnderageAlert] = useState(false);

  const [dateDay, setDateDay]               = useState('');
  const [dateMonth, setDateMonth]           = useState('');
  const [dateYear, setDateYear]             = useState('');
  const [dateFieldFocus, setDateFieldFocus] = useState(DATE_FIELD_DAY);

  const validateDate = useCallback((value) => {
    let setError = false;

    if (value.length === 10) {
      const valueDate = new Date(value.split('/').reverse().join('-'));
      setError        = true;

      if (valueDate instanceof Date && !isNaN(valueDate)) {
        const result = validateBirthDate(valueDate);
        if (result === validateBirthDateResult.OK) {
          setError = false;
          onSubmit(valueDate);
        } else if (result === validateBirthDateResult.UNDERAGE) {
          setShowUnderageAlert(true);
        }
      }
    }
    setIsError(setError);
  }, 
  [onSubmit]);

  /**
   * Check if the date fields are filled and can be submitted.
   */
  useEffect(() => {
    if ((dateDay !== '') && (dateMonth !== '') && (dateYear !== '') && (dateYear.length === 4)) {
      const dateValue = ('0' + dateDay).substr(-2) + '/' + ('0' + dateMonth).substr(-2) + '/' + dateYear;
      validateDate(dateValue);
    }
  }, [dateDay, dateMonth, dateYear, validateDate]);


  const error = t('corona.page.age.verification.invalid');

  return (
    <Page
      className={'p-age-verification'}
      title={t('corona.page.age.verification.title')}
      image={pageAnimation}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    >
      <p>
        {t('corona.page.age.verification.description')}
      </p>
      <FormGroup
        error={isError ? error : ''}
      >
        {/*
         <CodeInput
         appearance={CodeInput.APPEARANCES.DATE}
         onChange={(value) => validateDate(value)}
         error={isError}
         />
         */}
        <div className={'p-age-verification__input-group'}>
          <InputNumber
            data-test-id="day-input"
            className={'p-age-verification__input p-age-verification__input--day'}
            type={'number'}
            pattern="\d*"
            inputMode={'numeric'}
            maxLength={2}
            name={'date-day'}
            value={dateDay}
            placeholder={'DD'}
            focus={dateFieldFocus === DATE_FIELD_DAY}
            error={isError}
            onChange={(evt) => {
              const {value} = evt.target;
              if (value.length <= 2) {
                setDateDay(value);
                if (value.length === 2) {
                  setDateFieldFocus(DATE_FIELD_MONTH);
                }
              }
            }}
          />
          <span className={'p-age-verification__date-separator'}>/</span>
          <InputNumber
            data-test-id="month-input"
            className={'p-age-verification__input p-age-verification__input--month'}
            type={'number'}
            pattern="\d*"
            inputMode={'numeric'}
            maxLength={2}
            name={'date-month'}
            value={dateMonth}
            placeholder={'MM'}
            focus={dateFieldFocus === DATE_FIELD_MONTH}
            error={isError}
            onChange={(evt) => {
              const {value} = evt.target;
              if (value.length <= 2) {
                setDateMonth(value);
                if (value.length === 2) {
                  setDateFieldFocus(DATE_FIELD_YEAR);
                }
              }
            }}
          />
          <span className={'p-age-verification__date-separator'}>/</span>
          <InputNumber
            data-test-id="year-input"
            className={'p-age-verification__input p-age-verification__input--year'}
            type={'number'}
            pattern="\d*"
            inputMode={'numeric'}
            maxLength={4}
            name={'date-year'}
            value={dateYear}
            placeholder={'YYYY'}
            focus={dateFieldFocus === DATE_FIELD_YEAR}
            error={isError}
            onChange={(evt) => {
              const {value} = evt.target;
              if (value.length <= 4) {
                setDateYear(value);
              }
            }}
          />
        </div>
      </FormGroup>
      {showUnderageAlert && (
        <Page
          onBackButtonClick={() => setShowUnderageAlert(false)}
          image={alertAnimation}
          title={t('corona.page.age.verification.rainy.title')}
          modal={true}
        >
          <p>
            {t('corona.page.age.verification.rainy.description')}
          </p>
        </Page>
      )}
    </Page>
  );
};

AgeVerificationPage.propTypes = {
  showBackButton   : PropTypes.bool,
  onSubmit         : PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
};

export default AgeVerificationPage;
