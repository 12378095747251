import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/Textarea.scss';

/**
 * Textarea component
 *
 * Reference is forwarded and can be used for own pleasure, focus can be reached by
 * using autoFocus as attribute or using the reference.
 *
 * @param {boolean} disabled
 * @param {boolean} error
 * @param {string} className
 * @param {string} value
 * @param {string} name
 * @param {string} placeholder
 * @param {number} maxLength
 * @param {number} rows
 * @param {string} resizeable
 * @param {*} props
 * @return {*}
 * @constructor
 */
const Textarea = forwardRef((
  {
    disabled,
    error,
    className,
    value,
    name,
    placeholder,
    maxLength,
    rows,
    resizeable,
    ...props
  }, ref) => (
    <textarea
      disabled={disabled}
      className={classNames('c-textarea', className, {
        'c-textarea--disabled'             : disabled,
        'c-textarea--error'                : error,
        'c-textarea__resizeable-both'      : resizeable === Textarea.RESIZEABLES.BOTH,
        'c-textarea__resizeable-horizontal': resizeable === Textarea.RESIZEABLES.HORIZONTAL,
        'c-textarea__resizeable-vertical'  : resizeable === Textarea.RESIZEABLES.VERTICAL,
        'c-textarea__resizeable-none'      : resizeable === Textarea.RESIZEABLES.NONE,
      })}
      name={name}
      ref={ref}
      placeholder={placeholder || ''}
      maxLength={maxLength}
      rows={rows}
      value={value}
      {...props}
    />
  )
);

// region component constants used for configuration.
Textarea.RESIZEABLES = {
  BOTH      : 'both',
  HORIZONTAL: 'horizontal',
  VERTICAL  : 'vertical',
  NONE      : 'none',
};
// endregion

// region component property configuration.
Textarea.propTypes = {
  disabled   : PropTypes.bool,
  className  : PropTypes.string,
  value      : PropTypes.any.isRequired,
  name       : PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength  : PropTypes.number,
  rows       : PropTypes.number,
  resizeable : PropTypes.oneOf([Textarea.RESIZEABLES.BOTH, Textarea.RESIZEABLES.HORIZONTAL, Textarea.RESIZEABLES.VERTICAL, Textarea.RESIZEABLES.NONE]),
};
// endregion

// region component property defaults.
Textarea.defaultProps = {
  disabled  : false,
  rows      : 3,
  resizeable: Textarea.RESIZEABLES.NONE,
};
// endregion

export default Textarea;
