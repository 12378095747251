import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/Checkbox.scss';

// checkbox display types
const CHECKBOX_TYPES = {
  REGULAR: "regular",
  SWITCH : "switch"
};

/**
 * Checkbox component
 *
 * @param {string} className
 * @param {boolean} checked
 * @param {boolean} disabled
 * @param {boolean} showCrossWhenDisabled
 * @param {string} name
 * @param {string} value
 * @param {string} text
 * @param {string} type
 * @param {boolean} error
 * @param {function} onChange
 * @return {*}
 * @constructor
 */
const Checkbox = (
  {
    className,
    checked,
    disabled,
    showCrossWhenDisabled = false,
    name,
    value,
    text,
    type = CHECKBOX_TYPES.REGULAR,
    error,
    onChange,
    ...props
  }) => {

  const [isFocused, setIsFocused] = useState(false);
  const containerClassName        = classNames('c-checkbox', className, {
    'is-focused'              : isFocused,
    'is-checked'              : checked,
    'is-disabled'             : disabled,
    'show-cross-when-disabled': showCrossWhenDisabled,
    'type-regular'            : type === CHECKBOX_TYPES.REGULAR,
    'type-switch'             : type === CHECKBOX_TYPES.SWITCH,
    'hasError'                : error
  });

  return (
    <div className={containerClassName}>
      <label className="c-checkbox__label">
        <input
          className="c-checkbox__input"
          name={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          value={value}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onChange={(evt) => {
            if (onChange) {
              onChange(evt);
            }
          }}
          {...props}
        />
        <span className="c-checkbox__icon-container">
          <span className="c-checkbox__icon"/>
        </span>
        {text && <span className="c-checkbox__label-text" dangerouslySetInnerHTML={{ __html: text }} />}
      </label>
    </div>);
};

Checkbox.propTypes = {
  className            : PropTypes.string,
  checked              : PropTypes.bool.isRequired,
  disabled             : PropTypes.bool,
  name                 : PropTypes.string,
  value                : PropTypes.string,
  type                 : PropTypes.oneOf([CHECKBOX_TYPES.REGULAR, CHECKBOX_TYPES.SWITCH]),
  text                 : PropTypes.string,
  error                : PropTypes.bool,
  showCrossWhenDisabled: PropTypes.bool,
  onChange             : PropTypes.func,
};

export default Checkbox;
