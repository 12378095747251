import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Page from 'components/page/Page';
import Form from 'components/form/Form';
import './assets/styles/OrganisationSpecificDetailsPage.scss';

/**
 * Represents the dynamic organisation details page, this page will
 * render all 'configurable' questions for the organisation.
 * @param {string} title
 * @param {array} questions
 * @param {object} values
 * @param {function}onBackButtonClick
 * @param {function} onSubmit
 * @return {*}
 * @constructor
 */
const OrganisationSpecificDetailsPage = (
  {
    values = {},
    onSubmit,
    questions,
    onBackButtonClick,
  }
) => {
  const {t} = useTranslation();

  return (
    <Page
      title={t('corona.page.organisation.specific.details.title')}
      className={'p-organisation-specific-details'}
      onBackButtonClick={onBackButtonClick}
    >
      <Form
        values={values}
        questions={questions}
        onSubmit={onSubmit}
        submitLabel={'corona.signup.button'}
      />
    </Page>
  );
};

// region component proptype configuration.
OrganisationSpecificDetailsPage.propTypes = {
  values           : PropTypes.object,
  questions        : PropTypes.arrayOf(PropTypes.shape({
    name : PropTypes.string,
    type : PropTypes.oneOf([
      Form.QUESTION_TYPES.TEXT,
      Form.QUESTION_TYPES.SELECT,
      Form.QUESTION_TYPES.RADIO,
      Form.QUESTION_TYPES.CHECKBOX,
      Form.QUESTION_TYPES.TEXTAREA,
      Form.QUESTION_TYPES.LEGAL,
    ]).isRequired,
    label: PropTypes.string,
  })),
  onBackButtonClick: PropTypes.func,
  onSubmit         : PropTypes.func,
};
// endregion

// region component default values.
OrganisationSpecificDetailsPage.defaultProps = {
  questions: [],
};
// endregion

export default OrganisationSpecificDetailsPage;