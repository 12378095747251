import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Page from 'components/page/Page';
import PageAnimation from 'assets/lottie/onboard-program-code';
import FormGroup from 'components/formgroup/FormGroup';
import CodeInput from 'components/codeinput/backup/CodeInput';

import './assets/styles/ProgramCodePage.scss';


/**
 * Program code page component
 *
 * @param {string} error
 * @param {function} onBackButtonClick
 * @param {function} onSubmit
 * @return {*}
 * @constructor
 */
const ProgramCodePage = ({ error, expectedCodeLength = 4, onBackButtonClick, onSubmit }) => {
  // translation helper.
  const { t } = useTranslation();

  const [values, setValues] = useState(new Array(expectedCodeLength).fill(""));

  const digitsCount = 6;

  const handleOnChange = useCallback((value) => {
    setValues(value.split(""));

    if (value.length === digitsCount) {
      onSubmit(value);
    }
  }, [onSubmit]);

  return (
    <Page className={'p-program-code'}
          image={PageAnimation}
          title={t('corona.page.program.code.title')}
          onBackButtonClick={onBackButtonClick}>
      <p>{t('corona.page.program.code.description')}</p>
      <FormGroup error={error}>
        <CodeInput error={(!!error)} onChange={handleOnChange} fields={digitsCount} values={values} />
      </FormGroup>
    </Page>
  );
};

ProgramCodePage.propTypes = {
  error: PropTypes.string,
  expectedCodeLength: PropTypes.number,
  onBackButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ProgramCodePage;
