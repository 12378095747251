import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./assets/styles/Input.scss";

/**
 * Represents an input field in the Luscii web application, can represents a normal
 * text input or one of the other types; email, number or password.
 *
 * Reference is forwarded and can be used for own pleasure, focus can be reached by
 * using autoFocus as attribute or using the reference.
 *
 * @param {boolean} disabled
 * @param {boolean} error
 * @param {string} className
 * @param {string} type
 * @param {string} value
 * @param {string} name
 * @param {string} placeholder
 * @param {number} maxLength
 * @param {*} props
 * @return {*}
 * @constructor
 */
const Input = React.forwardRef(
  (
    {
      disabled,
      error,
      className,
      type,
      value,
      name,
      placeholder,
      maxLength,
      ...props
    },
    ref
  ) => (
    <input
      disabled={disabled}
      className={classNames("c-input", className, {
        "c-input--disabled": disabled,
        "c-input--error": error,
      })}
      name={name}
      type={type}
      ref={ref}
      value={value}
      placeholder={placeholder || ""}
      maxLength={maxLength}
      {...props}
    />
  )
);

// region component constants used for configuration.
Input.TYPES = {
  HIDDEN: "hidden",
  EMAIL: "email",
  NUMBER: "number",
  PASSWORD: "password",
  TEXT: "text",
  TEL: "tel",
  DATE: "date",
};
// endregion

// region component property configuration.
Input.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    Input.TYPES.HIDDEN,
    Input.TYPES.EMAIL,
    Input.TYPES.NUMBER,
    Input.TYPES.PASSWORD,
    Input.TYPES.TEXT,
    Input.TYPES.TEL,
    Input.TYPES.DATE,
  ]).isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};
// endregion

// region component property defaults.
Input.defaultProps = {
  disabled: false,
  type: Input.TYPES.TEXT,
};
// endregion

export default Input;
