import React, {useEffect} from 'react';
import Page from 'components/page/Page';
import alertAnimation from "assets/lottie/onboard-locked.json";
import {useTranslation} from 'react-i18next';
import './assets/styles/CreatingAccountErrorPage.scss';

/**
 * Represents the creating account error page.
 * @return {*}
 * @constructor
 */
const CreatingAccountErrorPage = (
  {
    email,
    username,
  }
) => {
  // translation helper.
  const {t} = useTranslation();

  useEffect(
    () => {
      setTimeout(() => {
        if (window) {
          if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.registrationDone.postMessage(username)
          }

          if (window.Android) {
            window.Android.registrationDone(username)
          }
        }
      }, 3000)
    },
    [username]
  );

  return (
    <Page
      showBackButton={false}
      image={alertAnimation}
      title={t('corona.page.creating.account.rainy.title')}>
      <p dangerouslySetInnerHTML={{ __html: t('corona.page.creating.account.rainy.description')}} />
    </Page>

  );
};

export default CreatingAccountErrorPage;
