import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Page from "components/page/Page";
import Form from "components/form/Form";
import "./assets/styles/PersonalDetailsPage.scss";

/**
 * Personal details page component
 *
 * @param {string} countryCode
 * @param {array} values
 * @param {function} onSubmit
 * @param {function} onBackButtonClick
 * @return {*}
 * @constructor
 */
const PersonalDetailsPage = ({
  values = {},
  onSubmit,
  questions,
  showBackButton,
  onBackButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <Page
      title={t("corona.page.personal.details.title")}
      className={"p-personal-details-page"}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    >
      <Form
        values={values}
        questions={questions}
        onSubmit={onSubmit}
        submitLabel={"corona.general.button.next.title"}
      />
    </Page>
  );
};

PersonalDetailsPage.propTypes = {
  values: PropTypes.object,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf([
        Form.QUESTION_TYPES.TEXT,
        Form.QUESTION_TYPES.SELECT,
        Form.QUESTION_TYPES.RADIO,
        Form.QUESTION_TYPES.CHECKBOX,
        Form.QUESTION_TYPES.TEXTAREA,
        Form.QUESTION_TYPES.LEGAL,
        Form.QUESTION_TYPES.DATE,
        Form.QUESTION_TYPES.LABEL,
      ]).isRequired,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      whitelist: PropTypes.shape({
        programs: PropTypes.arrayOf(PropTypes.string),
      }),
      blacklist: PropTypes.shape({
        programs: PropTypes.arrayOf(PropTypes.string),
      }),
    })
  ),
  onSubmit: PropTypes.func,
  onBackButtonClick: PropTypes.func,
};

PersonalDetailsPage.defaultProps = {
  questions: [],
};

export default PersonalDetailsPage;
