import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './assets/styles/Select.scss';

/**
 * Represents a select component in the luscii web application.
 *
 * @param {string} className
 * @param {string} name
 * @param {boolean} disabled
 * @param {boolean} error
 * @param {object} options
 * @param {string|number} value
 * @param {function} onChange
 * @param {object} props
 * @return {*}
 * @constructor
 */
const Select = (
  {
    className,
    name,
    disabled,
    error,
    options,
    value,
    onChange,
    ...props
  }
) => (
  <select
    className={classNames('c-select', className, {
      'c-select--disabled': disabled,
      'c-select--error'   : error,
    })}
    disabled={disabled}
    value={value}
    onChange={(e) => {
      if (!disabled && onChange) {
        onChange(e.target.value);
      }
    }}
    {...props}
  >
    {Object.keys(options).map((value, key) => (
      <option
        key={`select-option-${key}`}
        value={value}
      >
        {options[value]}
      </option>
    ))}
  </select>
);

Select.propTypes = {
  disabled : PropTypes.bool,
  error    : PropTypes.bool,
  name     : PropTypes.string,
  className: PropTypes.string,
  value    : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options  : PropTypes.object.isRequired,
  onChange : PropTypes.func.isRequired,
};

Select.defaultProps = {
  options : {},
  disabled: false,
  onChange: () => {
  },
};

export default Select;
