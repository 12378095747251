import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './assets/styles/ListItem.scss';

/**
 * List item component
 *
 * @param {string} className
 * @param {*} children
 * @param {object} props
 * @return {*}
 * @constructor
 */
const ListItem = ({className, children, ...props}) => (
  <div className={classNames('c-list-item', className)} {...props}>
    {children}
  </div>
);

ListItem.propTypes = {
  className: PropTypes.string,
  children : PropTypes.node,
};

export default ListItem;
