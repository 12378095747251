import React, {useEffect, useRef} from 'react';
import Input from "components/input/Input";

/**
 * Number input component. A fast hacked input field that only accepts numbers.
 *
 * @param {boolean} focus
 * @param {object} otherProps
 * @return {*}
 * @constructor
 */
const InputNumber = (
  {
    focus,
    ...otherProps
  }) => {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef && focus) {
      inputRef.current.focus();
    }
  }, [focus, inputRef]);

  return (
    <Input
      ref={inputRef}
      onKeyDown={(evt) => {
        const {key}       = evt;
        const allowedKeys = [
          'Tab',
          'ArrowLeft',
          'ArrowRight',
          'Backspace',
          'Delete',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '0',
        ];

        if (allowedKeys.indexOf(key) === -1) {
          evt.stopPropagation();
          evt.preventDefault();
        }
      }}
      {...otherProps}
    />
  );
};

export default InputNumber;