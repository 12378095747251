import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Page from 'components/page/Page';
import List from 'components/list/List';
import ListItem from 'components/list/ListItem';
import ListItemText from 'components/list/ListItemText';
import ListItemIcon from 'components/list/ListItemIcon';
import ListItemButton from 'components/list/ListItemButton';
import './assets/styles/SelectCountryPage.scss';

/**
 * Select country page component
 *
 * @param {array} countries
 * @param {function} onSubmit
 * @param {function} onBackButtonClick
 * @return {*}
 * @constructor
 */
const SelectCountryPage = (
  {
    countries,
    onSubmit,
    onBackButtonClick,
  }) => {
  // translation helper.
  const {t} = useTranslation();

  return (
    <Page
      className={'p-select-country'}
      title={t('corona.country-selector.title')}
      onBackButtonClick={onBackButtonClick}
      showBackButton={false}
      noContentPadding={true}
    >
      <List>
        {countries && countries.map((item) => (
          <ListItem key={item.id} onClick={() => onSubmit(item)}>
            <ListItemIcon><img src={item.image} alt={item.text} title={''} className={'item-image'}/></ListItemIcon>
            <ListItemText primary={t(item.text)}/>
            <ListItemButton role={'primary'} data-test-id={`country-select-button-${item.id}`}>
              {t('corona.general.button.start.title')}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};

SelectCountryPage.propTypes = {
  countries        : PropTypes.arrayOf(PropTypes.shape({
    id      : PropTypes.string,
    title   : PropTypes.string,
    subtitle: PropTypes.string,
    image   : PropTypes.string,
  })),
  onSubmit         : PropTypes.func,
  onBackButtonClick: PropTypes.func,
};

export default SelectCountryPage
