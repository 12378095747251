import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Page from "components/page/Page";
import List from "components/list/List";
import ListItem from "components/list/ListItem";
import ListItemIcon from "components/list/ListItemIcon";
import ListItemText from "components/list/ListItemText";
import ListItemButton from "components/list/ListItemButton";
import "./assets/styles/CareProvidersPage.scss";

/**
 * Care providers page component
 *
 * @param {array} careProviders
 * @param {boolean} showBackButton
 * @param {function} onBackButtonClick
 * @param {function} onSubmit
 * @return {*}
 * @constructor
 */
const CareProvidersPage = ({
  careProviders,
  showBackButton,
  onBackButtonClick,
  onSubmit,
}) => {
  // translation helper.
  const { t } = useTranslation();

  // The data must exist in the app, but we won't show it.
  // It's only accessible via a direct url.
  const excludedProviders = ["luscii-library-demo"];

  return (
    <Page
      className={"p-care-providers-page"}
      title={t("corona.page.care.provider.title")}
      noContentPadding={true}
      showBackButton={showBackButton}
      onBackButtonClick={onBackButtonClick}
    >
      <List>
        {careProviders
          ?.filter(
            (careProvider) => !excludedProviders.includes(careProvider.id)
          )
          .map((item) => (
            <ListItem key={item.id} onClick={() => onSubmit(item)}>
              <ListItemIcon>
                <img
                  src={item.image}
                  alt={item.title}
                  title={""}
                  className={"item-image"}
                />
              </ListItemIcon>
              <ListItemText primary={t(item.title)} secondary={item.subtitle} />
              <ListItemButton
                role={"primary"}
                data-test-id={`care-provider-${item.id}`}
              >
                Start
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Page>
  );
};

CareProvidersPage.propTypes = {
  showBackButton: PropTypes.bool,
  careProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  onBackButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CareProvidersPage;
