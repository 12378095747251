import React, {useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import Page from 'components/page/Page';
import Box from 'components/box/Box';
import PageAnimation from 'assets/lottie/onboarding-created';
import './assets/styles/CompletedPage.scss';

/**
 * Completed page component
 *
 * @param {string} email
 * @return {*}
 * @constructor
 */
const CompletedPage = (
  {
    email,
    username,
  }
) => {
  // translation helper.
  const {t} = useTranslation();

  useEffect(
    () => {
      setTimeout(() => {
        if (window) {
          if (window.webkit && window.webkit.messageHandlers) {
            window.webkit.messageHandlers.registrationDone.postMessage(username);
          }

          if (window.Android) {
            window.Android.registrationDone(username)
          }
        }
      }, 3000)
    },
    [username]
  );

  return (
    <Page
      image={PageAnimation}
      showBackButton={false}
      title={t('corona.page.completed.title')}
      className={'p-completed'}
      data-test-id="register-completed-page"
    >
      <Box appearance={Box.APPEARANCE_DEFAULT}>
        {t('corona.page.completed.description.title')}
      </Box>
      <Trans t={t}>
        corona.page.completed.description.content
      </Trans>
    </Page>
  );
};

export default CompletedPage;
