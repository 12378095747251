import React, {useState, useEffect} from 'react';
import Lottie from "react-lottie";
import PropTypes from 'prop-types';

/**
 * Animation component for lottie animations
 *
 * @param {number} width
 * @param {number} height
 * @param {boolean} loop
 * @param {object} animationData
 * @param {number} animationDelay
 * @return {*}
 * @constructor
 */
const Animation = ({width, height, loop, animationData, animationDelay}) => {
  const [stopped, setStopped] = useState(true);

  // set up the animation delay functionality, animation is stopped by default
  // and will be started after the delay has passed.
  useEffect(
    () => {
      let timeout = setTimeout(
        () => {
          setStopped(false);
        },
        animationDelay
      );

      return () => {
        clearTimeout(timeout);
      }
    },
    [animationDelay]
  );

  return (
    <Lottie
      height={width}
      width={height}
      isStopped={stopped}
      options={{
        autoplay     : false,
        loop         : loop,
        animationData: animationData
      }}
    />
  );
};

Animation.propTypes = {
  width         : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height        : PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loop          : PropTypes.bool,
  animationData : PropTypes.object,
  animationDelay: PropTypes.number,
};

Animation.defaultProps = {
  loop          : true,
  animationDelay: 250
};

export default Animation;