export const validateEmail = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export const validateNumber = (number) => {
  const regex = /^[0-9]*$/;
  return regex.test(number);
}

export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^[0-9\+\s-]{8,13}$/;
  return regex.test(phoneNumber);
}

export const validateMinLength = (value, minLength) => {
  return `${value}`.trim().length >= minLength;
}

export const validateRequired = (value) => {
  return validateMinLength(value, 1);
}

export const validateUsername = (username) => {
  const regex = /^\S*$/i;
  return regex.test(username.trim());
}
