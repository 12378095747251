import { initialize } from "launchdarkly-js-client-sdk";

export class FeatureFlagService {
  static getFeatureFlags(userKey) {
    return new Promise((resolve) => {
      let clientKey;
      switch (process.env.REACT_APP_ENV) {
        case "test":
          clientKey = "5f57803978bf1a09747760b8";
          break;
        case "acceptance":
          clientKey = "5f60a553d39e0c0ab58a9f36";
          break;
        default:
          clientKey = "5f57803978bf1a09747760b9";
      }

      const client = initialize(clientKey, { key: userKey, anonymous: true });

      client.on("ready", () => {
        resolve(client.allFlags()); // returns empty object if client failed
      });
    });
  }
}
