// states that can be returned by validateBirthDate
export const validateBirthDateResult = {
  OK: true,
  UNDERAGE: false,
  ERROR: "error",
};

/**
 * Check if the date is valid and is 18 years or more from the current time.
 *
 * @param {Date} valueDate
 * @return {boolean|string}
 */
export const validateBirthDate = (valueDate) => {
  const currentDate = new Date();
  if (valueDate instanceof Date && !isNaN(valueDate)) {
    // check if the date is not in the future.
    if (valueDate < currentDate) {
      if (Math.abs(valueDate.getFullYear() - currentDate.getFullYear()) < 18) {
        // underaged
        return validateBirthDateResult.OK;
        // TODO: disabled due to ukrainian program
        // return validateBirthDateResult.UNDERAGE;
      } else {
        // not underaged
        return validateBirthDateResult.OK;
      }
    }
  }
  // failed
  return validateBirthDateResult.ERROR;
};

export const checkIfValidEmail = (control) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(control).toLowerCase());
};
