import React, {Suspense} from 'react';
import Onboard from "components/onboard/Onboard";
import './assets/styles/App.scss';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

/**
 * App component
 *
 * @return {*}
 * @constructor
 */
const App = () => (
  <Suspense fallback={<Loader/>}>
    <div className={'c-app'}>
      <Onboard />
    </div>
  </Suspense>
);

export default App;

