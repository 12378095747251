export const PROGRAM_ID = {
  CORONA_CHECK: "corona-check",
  HOME_AFTER_CORONA: "home-after-corona",
  FEYENOORD: "erasmus-mc-feyenoord",
  CORONA_CONNECT: "corona-connect",
  HOME_WITH_OXYGEN_CORONA: "home-with-oxygen-corona",
  HOME_WITH_OXYGEN_INFLUENZA: "home-with-oxygen-influenza",
  HOME_WITH_SATURATION_CORONA: "home-with-saturation-corona",
  HOME_WITH_CORONA: "home-with-corona",
  CHECK_AT_HOME: "check-at-home",

  allProgramsOfTheNetherlands: () => [
    PROGRAM_ID.HOME_AFTER_CORONA,
    PROGRAM_ID.FEYENOORD,
    PROGRAM_ID.CORONA_CONNECT,
    PROGRAM_ID.HOME_WITH_OXYGEN_CORONA,
    PROGRAM_ID.HOME_WITH_OXYGEN_INFLUENZA,
    PROGRAM_ID.HOME_WITH_SATURATION_CORONA,
    PROGRAM_ID.HOME_WITH_CORONA,
    PROGRAM_ID.CHECK_AT_HOME,
  ],
};

export const COUNTRY = {
  NL: "nl",
  GB: "gb",
  GH: "gh",
  IE: "ie",
  KE: "ke",
  NG: "ng",
};
