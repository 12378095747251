import React, {useEffect} from 'react';
import Page from 'components/page/Page';
import pageAnimation from 'assets/lottie/onboard-account-creation';
import {useTranslation} from 'react-i18next';
import './assets/styles/CreatingAccountPage.scss';

/**
 * Represents the creating account page, this will give us the chance
 * to gather and submit all the data that we got.
 * @param {string} title
 * @return {*}
 * @constructor
 */
const CreatingAccountPage = (
  {
    onRegisterAccount,
  }
) => {
  // translation helper.
  const {t} = useTranslation();

  // small delay before calling the on register account method.
  useEffect(
    () => {
      const timeout = setTimeout(onRegisterAccount, 10);

      return () => {
        clearTimeout(timeout);
      }
    }
  );

  return (
    <Page
      className={'p-creating-account'}
      title={t('corona.page.creating.account.title')}
      image={pageAnimation}
      showBackButton={false}
    />
  );
};

export default CreatingAccountPage;