import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./assets/styles/Button.scss";

/**
 * Represents a single button in the Luscii web application.
 *
 * Usage:
 *
 * ```
 * <Button
 *  role={Button.ROLES.PRIMARY}
 *  onClick={(event) => {
 *    // pressed!
 *  }}
 * >
 *   Press Me
 * </Button>
 * ```
 *
 * Gotcha's:
 * - onClick events are not triggered when the button it disabled.
 *
 * @param {string} className
 * @param {string} type (Defaults to Button.TYPES.BUTTON)
 * @param {string} role (Defaults to Button.ROLES.PRIMARY)
 * @param {boolean} disabled
 * @param {function} onClick (Optional)
 * @param {node} children (Optional)
 * @param {object} props (All other properties will be spread and passed to the component)
 * @return {*}
 * @constructor
 */
const Button = (
  {className, type, role, disabled, onClick, children, ...props}
) => (
  <button
    type={type}
    disabled={disabled}
    className={classNames('c-button', className, {
      'c-button__role-primary'    : role === Button.ROLES.PRIMARY,
      'c-button__role-secondary'  : role === Button.ROLES.SECONDARY,
      'c-button__role-destructive': role === Button.ROLES.DESTRUCTIVE,
      'c-button__role-default'    : role === Button.ROLES.DEFAULT,
      'c-button--disabled'        : disabled === true,
    })}
    onClick={(event) => {
      if (!disabled && onClick) onClick(event);
    }}
    {...props}
  >
    {children}
  </button>
);

// region component constants used for configuration.
Button.ROLES = {
  PRIMARY    : 'primary',
  SECONDARY  : 'secondary',
  DESTRUCTIVE: 'destructive',
  DEFAULT    : 'default',
};

Button.TYPES = {
  BUTTON: 'button',
  SUBMIT: 'submit',
};
// endregion

// region component property configuration.
Button.propTypes = {
  className: PropTypes.string,
  disabled : PropTypes.bool,
  role     : PropTypes.oneOf([
    Button.ROLES.SECONDARY,
    Button.ROLES.PRIMARY,
    Button.ROLES.DESTRUCTIVE,
    Button.ROLES.DEFAULT,
  ]),
  type     : PropTypes.oneOf([
    Button.TYPES.BUTTON,
    Button.TYPES.SUBMIT,
  ]),
  onClick  : PropTypes.func,
};
// endregion

// region component property defaults.
Button.defaultProps = {
  type: Button.TYPES.BUTTON,
  role: Button.ROLES.DEFAULT
};
// endregion

export default Button;
