import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import Page from 'components/page/Page';
import FormGroup from 'components/formgroup/FormGroup';
import Input from "components/input/Input";
import Button from "components/button/Button";

import './assets/styles/AccountNamePage.scss';



/**
 * Account name page component
 *
 * @param {string} error
 * @param {function} onBackButtonClick
 * @param {function} onSubmit
 * @return {*}
 * @constructor
 */
const AccountNamePage = ({ values = {}, error, onBackButtonClick, onSubmit }) => {
  // translation helper.
  const { t } = useTranslation();

  const [value, setValue] = useState(values.accountName || "");

  const handleOnChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const validateFormData = (event) => {
    event.preventDefault();

    onSubmit({ accountName: value });
  };

  return (
    <Page className={'p-account-name'}
          title={t('corona.page.program.account.title')}
          onBackButtonClick={onBackButtonClick}>
      <FormGroup label={t('corona.page.program.account.form.title')} error={error}>
        <Input
          data-test-id="form-field-username"
          type={Input.TYPES.TEXT}
          value={value}
          name="account_name"
          error={(!!error)}
          placeholder={t('corona.page.program.account.form.placeholder')}
          onChange={handleOnChange}
        />
      </FormGroup>
      <div className={'c-form__footer'}>
        <Button
          data-test-id="form-field-next-button"
          role={'primary'}
          onClick={validateFormData}>
          {t('corona.general.button.next.title')}
        </Button>
      </div>
    </Page>
  );
};

AccountNamePage.propTypes = {
  error: PropTypes.string,
  onBackButtonClick: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AccountNamePage;
