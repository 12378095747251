// FIXME: For future refactoring, would be good to clean this file up.
// This was disabled because eslint will try to solve the dependency issues between functions and
// it is not smart enough to do it in right.
/* prettier-ignore */
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import Api from "utils/api";
import { useTranslation } from "react-i18next";

import { useStateWithCallbackLazy } from "utils/useState.custom";
import {
  checkIfValidEmail,
  validateBirthDate,
  validateBirthDateResult,
} from "utils/utils";
import { validateMinLength, validateUsername } from "utils/validators";
import { PROGRAM_ID, COUNTRY } from "utils/constants";
import { FeatureFlagService } from "utils/featureFlagService";
import SelectCountryPage from "pages/SelectCountryPage/SelectCountryPage";
import AgeVerificationPage from "pages/AgeVerficationPage/AgeVerificationPage";
import PostalCodePage from "pages/PostalCodePage/PostalCodePage";
import PostalCodeNoResultsPage from "pages/PostalCodePage/PostalCodeNoResultsPage";
import CareProvidersPage from "pages/CareProvidersPage/CareProvidersPage";
import CareProgramPage from "pages/CareProgramPage/CareProgramPage";
import ProgramCodePage from "pages/ProgramCodePage/ProgramCodePage";
import ProgramInformationPage from "pages/ProgramInformationPage/ProgramInformationPage";
import PersonalDetailsPage from "pages/PersonalDetailsPage/PersonalDetailsPage";
import AccountNamePage from "pages/AccountNamePage/AccountNamePage";
import OrganisationSpecificDetailsPage from "pages/OrganisationSpecificDetailsPage/OrganisationSpecificDetailsPage";
import CreatingAccountPage from "pages/CreatingAccountPage/CreatingAccountPage";
import CreatingAccountErrorPage from "pages/CreatingAccountErrorPage/CreatingAccountErrorPage";
import CompletedPage from "pages/CompletedPage/CompletedPage";
import Form from "components/form/Form";

import "./assets/styles/Onboard.scss";

// available steps
const STEPS = {
  country: 1,
  age: 2,
  postal: 3,
  postalnoresults: 4,
  careproviders: 5,
  careprogram: 6,
  programcode: 7,
  careprograminformation: 8,
  personaldetails: 9,
  accountname: 10,
  organisationspecficdetails: 11,
  creatingaccount: 12,
  creatingaccounterror: 13,
  completed: 14,
};

/**
 * Onboard component
 *
 * @return {*}
 * @constructor
 */
const Onboard = () => {
  const [featureFlags, setFeatureFlags] = useState({});
  const [countries, setCountries] = useState([]);
  const [careProviders, setCareProviders] = useState([]);
  const [carePrograms, setCarePrograms] = useState([]);
  const [wizardData, setWizardData] = useStateWithCallbackLazy({});
  const [goToAgeSelection, setGoToAgeSelection] = useState(false);
  const [step, setStep] = useState();
  const [steps, setSteps] = useState([]);
  const [programCodeError, setProgramCodeError] = useState(null);
  const [accountNameError, setAccountNameError] = useState(null);

  // we can extract parameters from window location
  const search = window.location.search;
  const params = new URLSearchParams(search);

  // Checking the feature flag
  useEffect(() => {
    // if (featureFlags["useExampleFlag"] === true) {
    //   setIsExampleFlagUsedState(true);
    // }
  }, [featureFlags]);

  // region change language based on given language parameter
  const language = params.get("language");
  const { i18n, t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => changeLanguage(language), [language]);
  // endregion

  // region support query parameters
  const queryMobile = parseInt(params.get("mobile")) === 1;
  const querySkipPostalCodeCheck =
    parseInt(params.get("skipPostalCodeCheck")) === 1;
  const queryBirthdate = params.get("birthdate");
  const queryEmail = checkIfValidEmail(params.get("email"))
    ? params.get("email")
    : undefined;
  const queryUsername = !checkIfValidEmail(params.get("email"))
    ? params.get("email")
    : undefined;
  const queryOrg = params.get("org");
  const queryProgram = params.get("program");
  const queryCountry =
    PROGRAM_ID.allProgramsOfTheNetherlands().indexOf(queryProgram) > -1
      ? COUNTRY.NL
      : params.get("country");
  const queryPatientId = params.get("patient_id");
  // endregion

  // region initialization of data on mount
  useEffect(() => {
    const getRequiredProgramData = async ({ program, country }) => {
      const careProviders = await Api.getCareProviders(country);

      let programData;
      careProviders.forEach((provider) => {
        provider.programs.forEach((providerProgram) => {
          if (providerProgram.id === program) {
            programData = providerProgram;
          }
        });
      });

      if (programData && programData.showProgramCodeCheck) {
        return programData;
      }

      return null;
    };
    const extraContext = {};

    Promise.all([
      Api.getCountries(),
      FeatureFlagService.getFeatureFlags(),
      Api.getCareProviders(queryCountry || "nl"),
    ]).then(
      ([receivedCountries, receivedFeatureFlags, receivedCareProviders]) => {
        setCountries(receivedCountries);
        setFeatureFlags(receivedFeatureFlags);

        // This is how we set the care provider coming from the url
        if (queryOrg && receivedCareProviders?.length > 0) {
          const foundOrg = receivedCareProviders.find(
            (org) => org.id === queryOrg
          );
          if (foundOrg) {
            setWizardDataValue("careProvider", foundOrg);
          }
        }
      }
    );

    // if query email is given pre-fill that email value.
    if (queryEmail) {
      setWizardDataValue("email", queryEmail);
    }

    // if query username is given pre-fill that username value.
    if (queryUsername) {
      setWizardDataValue("accountName", queryUsername);
    }

    if (queryPatientId) {
      setWizardDataValue("patient_id", queryPatientId);
    }

    // if query birthdate is given pre-fill that birthdate value.
    if (queryBirthdate) {
      const date = new Date(queryBirthdate);
      if (date instanceof Date && !isNaN(date)) {
        if (validateBirthDate(date) === validateBirthDateResult.OK) {
          setWizardDataValue("birthdate", date);
          extraContext.birthdate = date;
        }
      }
    }

    // if country given we need to skip this step but we need to verify the
    // given country first.
    if (queryCountry) {
      Api.getCountry(queryCountry).then((countryItem) => {
        if (countryItem) {
          setWizardDataValue("country", countryItem);

          if (queryProgram) {
            getRequiredProgramData({
              program: queryProgram,
              country: queryCountry,
            }).then((program) => {
              if (program && program.showProgramCodeCheck) {
                setGoToAgeSelection(true);
                setWizardDataValue("careProgram", program);
                setCurrentStep(STEPS.programcode);
              } else {
                gotoNextStep(STEPS.country, { country: countryItem, ...extraContext });
              }
            });
          } else {
            gotoNextStep(STEPS.country, { country: countryItem, ...extraContext });
          }
        } else {
          // country code could not be found, let the user select
          // a country anyway.
          setCurrentStep(STEPS.country);
        }
      });
    } else {
      setCurrentStep(STEPS.country);
    }

    setWizardDataValue("mobile", queryMobile);
  }, []);
  // endregion

  // region functions
  /**
   * Set data key in de wizardData state.
   *
   * @param {string} item
   * @param {*} value
   */
  const setWizardDataValue = (item, value) => {
    setWizardData((data) => {
      return {
        ...data,
        [item]: value,
      };
    });
  };

  /**
   * Assign the current wizard step to activate, also keep track of all steps that were assigned
   * so we can navigate back and forth.
   * @param {number} currentStep
   * @param {array} currentSteps (Optional)
   */
  const setCurrentStep = (currentStep, currentSteps = steps) => {
    if (
      currentSteps.length === 0 ||
      currentSteps[steps.length - 1] !== currentStep
    ) {
      setSteps([...currentSteps, currentStep]);
    }
    setStep(currentStep);
  };

  /**
   * Assign the current care provider and navigate to the next wizard step.
   * @param {object} careProvider
   */
  const setCareProviderAndStep = (careProvider, context = {}) => {
    setWizardDataValue("careProvider", careProvider);

    // select the first and only program if the provider has only one to offer
    // or try to find the program if a url params has a program id value
    let careProgram = null;
    if (careProvider.programs.length === 1) {
      careProgram = careProvider.programs[0];
    } else if (queryProgram) {
      careProgram = careProvider.programs.find(
        (program) => program.id === queryProgram
      );
    }

    // automatically select the program, if exists
    // otherwise, retrieve and show the list of programs
    if (careProgram) {
      setWizardDataValue("careProgram", careProgram);
      gotoNextStep(STEPS.careprogram, { ...wizardData, ...context, careProgram });
    } else {
      Api.getPrograms(wizardData.country.value, careProvider.id).then((data) =>
        setCarePrograms(data)
      );
      setCurrentStep(STEPS.careprogram);
    }
  };

  /**
   * Assign the account name and navigate to the next wizard step.
   * @param {object} values
   */
  const setAccountNameAndStep = (values) => {
    const { accountName } = values;
    const { email } = wizardData;

    if (!validateMinLength(accountName, 3)) {
      setAccountNameError(t("corona.error.account.length"));
      return;
    }

    if (!validateUsername(accountName)) {
      setAccountNameError(t("corona.error.invalid"));
      return;
    }

    Api.getIsAccountNameAvailable(email, accountName)
      .then((isAvailable) => {
        if (isAvailable) {
          setAccountNameError(undefined);
          setWizardData((prevState) => ({ ...prevState, ...values }));
          gotoNextStep(step);
        } else {
          setAccountNameError(t("corona.page.program.account.form.error"));
        }
      })
      .catch((error) => {
        // TODO Handle this in a better way
        alert(t("corona.page.creating.account.rainy.title"));
      });
  };

  function gotoOrganizationSpecificDetails(account) {
    Api.getCareProviderQuestions(
      wizardData.country.id,
      wizardData.careProvider.id,
      wizardData.careProgram.id
    ).then((data) => {
      setAccountNameError(undefined);
      setWizardDataValue("accountName", account);
      setWizardDataValue("programQuestions", data);
      setCurrentStep(STEPS.organisationspecficdetails);
    });
  }

  /**
   * Determine next step.
   * @param {number} currentStep (Optional)
   * @param {object} context (Optional)
   */
  const gotoNextStep = (currentStep = undefined, context = undefined) => {
    const dataCombined = { ...wizardData, ...context }
    const { country, careProgram, birthdate, postalCode, programCode, email } = dataCombined;
    const { showPostalCodeCheck, showBirthDateCheck } = country || {};
    const { showProgramInformation, showProgramCodeCheck } = careProgram || {};

    const gotoPersonalDetails = (countryCode) => {
      Api.getCountryQuestions(countryCode).then((data) => {
        setWizardDataValue("countryQuestions", data);
        setCurrentStep(STEPS.personaldetails);
      });
    };

    console.log('gotoNextStep', currentStep, context, wizardData);

    switch (currentStep) {
      case STEPS.country:
        // if query birthdate was given and transformed into birthdate
        // we take this birthdate and skip the step.
        if (!showBirthDateCheck || (queryBirthdate && birthdate)) {
          gotoNextStep(STEPS.age, dataCombined);
        } else {
          setCurrentStep(STEPS.age);
        }
        break;

      case STEPS.age:
        // if postal code was configured show that step first, this is the step
        // that will fetch care providers based on postal code.
        if (showPostalCodeCheck && querySkipPostalCodeCheck !== true) {
          setCurrentStep(STEPS.postal);
          break;
        }
      // NOTE This line is kind of strange but it seems intentional to not have `break` statement here

      case STEPS.postal:
        // perform extra postal code check for care providers.
        Api.getCareProviders(country.id, postalCode).then((data) => {
          let filteredData = data || [];

          setCareProviders(filteredData);

          if (
            PROGRAM_ID.allProgramsOfTheNetherlands().indexOf(queryProgram) > -1
          ) {
            // if we only need care providers with queried program, we are filtering it here.
            filteredData = filteredData.filter((provider) => {
              return (
                provider.programs.filter(
                  (program) => program.id === queryProgram
                ).length > 0
              );
            });
          }

          if (filteredData.length === 0) {
            // when no results are available for care providers
            setCurrentStep(STEPS.postalnoresults);
          } else if (filteredData.length === 1) {
            // if we only have one provider; let's skip the selection
            // of the provider and just select the first.
            setCareProviderAndStep(filteredData[0], dataCombined);
          } else if (filteredData.length && queryOrg) {
            // if careProvider id is in the url, automatically choose it and skip the page
            const careProvider = filteredData.find((cp) => cp.id === queryOrg);
            if (careProvider) {
              setCareProviderAndStep(careProvider, dataCombined);
            } else {
              setCurrentStep(STEPS.careproviders);
            }
          } else {
            setCurrentStep(STEPS.careproviders);
          }
        });
        break;

      case STEPS.careprogram:
        if (showProgramCodeCheck) {
          // if we selected a care program check if the program code check
          // is enabled and programCode has not been entered yet;
          // if so show that first.
          setCurrentStep(STEPS.programcode);
        } else if (showProgramInformation) {
          // if we selected a care program and the show program info is
          // enabled; we show the program info.
          setCurrentStep(STEPS.careprograminformation);
        } else {
          // no program code or program info was configured so skip the
          // personal details step.
          gotoPersonalDetails((country || wizardData.country).id);
        }
        break;

      case STEPS.programcode:
        if (goToAgeSelection) {
          setCurrentStep(STEPS.age);
        } else if (showProgramInformation) {
          setCurrentStep(STEPS.careprograminformation);
        } else {
          gotoPersonalDetails((country || wizardData.country).id);
        }
        break;

      case STEPS.careprograminformation:
        gotoPersonalDetails((country || wizardData.country).id);
        break;

      case STEPS.personaldetails:
        {
          Api.getIsAccountNameAvailable(email)
            .then((isAvailable) => {
              if (isAvailable) {
                gotoOrganizationSpecificDetails(email);
              } else {
                setAccountNameError(undefined);
                setWizardDataValue("accountName", queryUsername);
                setCurrentStep(STEPS.accountname);
              }
            })
            .catch((error) => {
              console.trace(error);
              alert(t("corona.page.creating.account.rainy.title"));
            });
        }
        break;

      case STEPS.accountname:
        Api.getCareProviderQuestions(
          wizardData.country.id,
          wizardData.careProvider.id,
          wizardData.careProgram.id
        ).then((data) => {
          setWizardDataValue("programQuestions", data);
          setCurrentStep(STEPS.organisationspecficdetails);
        });
        break;

      case STEPS.organisationspecficdetails:
        setCurrentStep(STEPS.creatingaccount);
        break;
    }
  };

  /**
   * Navigate to the previous known wizard step.
   */
  const gotoPreviousStep = () => {
    const previousStep = (steps.length && steps[steps.length - 2]) || false;
    if (previousStep) {
      setSteps(steps.slice(0, steps.length - 2));
      setCurrentStep(previousStep, steps.slice(0, steps.length - 2));
    }
  };

  /**
   * Build the post data that we submit to the luscii api.
   * @returns {object}
   */
  const getPostData = () => {
    const {
      accountName,
      mobile,
      country,
      birthdate,
      postalCode,
      programQuestions,
      countryQuestions,
      careProvider,
      careProgram,
      ...questionsData
    } = wizardData;

    const isUkrainianLanguage = language === "uk";
    const overwriteLanguageOptions = isUkrainianLanguage
      ? { lng: "en" }
      : undefined;

    // region build a question data mapping required by the api.
    let mappedQuestionData = {};
    Object.values([...programQuestions, ...countryQuestions]).forEach(
      (question) => {
        const name = question.name;
        const type = question.type;

        // the label type question is just a visual element
        if (type === Form.QUESTION_TYPES.LABEL) return;

        const answer = questionsData[name];

        // check if the question was actually answered
        if (answer !== undefined) {
          // if question has options, check the actual translation key for this question
          // and include it for the luscii api.
          if (question.options) {
            if (Array.isArray(question.options)) {
              const questionOption = question.options.find(
                (option) => option.value === answer
              );
              mappedQuestionData[name] =
                (questionOption && questionOption.text) || "";
            } else {
              mappedQuestionData[name] = question.options[answer];
            }
            mappedQuestionData[`${name}Label`] = t(
              answer,
              overwriteLanguageOptions
            );
          } else if (type === Form.QUESTION_TYPES.HIDDEN) {
            mappedQuestionData[`${name}Label`] = t(
              answer,
              overwriteLanguageOptions
            );
          } else {
            mappedQuestionData[name] = answer;
          }
        }
      }
    );
    // endregion

    return {
      accountName: accountName,
      careProviderId: careProvider && careProvider.id,
      careProgramId: careProgram && careProgram.id,
      mobile: mobile,
      country: country && country.id,
      birthdate:
        birthdate &&
        birthdate.getFullYear() +
          "-" +
          ("0" + (birthdate.getMonth() + 1)).substr(-2, 2) +
          "-" +
          ("0" + birthdate.getDate()).substr(-2, 2),
      postalCode: postalCode,
      ...mappedQuestionData,
    };
  };
  // endregion

  // region determine which step needs to be rendered.
  let pageComponent;

  // For the luscii demo organization
  // We don't need program code check
  // We also skip for any direct program link, to make the flow easier

  const isLusciiDemoProgram =
    queryOrg?.toLowerCase() === "luscii-library-demo" &&
    queryProgram?.toLowerCase() === "copd";

  const shouldSkipCheck = isLusciiDemoProgram && step === STEPS.programcode;

  if (shouldSkipCheck) {
    const afterCodeStep = STEPS.programcode + 1;
    setStep(STEPS.age);
    setSteps([...steps, STEPS.postal, afterCodeStep]);
  }

  const handleOnRegister = () => {
    // #SUBMIT
    const data = getPostData();

    Api.registerUser(data)
      .then((response) => {
        const code = response.status;
        if (code >= 200 && code <= 299) {
          setCurrentStep(STEPS.completed);
        } else if (code === 409) {
          setCurrentStep(STEPS.creatingaccounterror);
        } else if (code >= 400 && code <= 500) {
          alert(t("corona.error.registration"));
          gotoPreviousStep();
        }
      })
      .catch((error) => {
        alert(t("corona.error.registration"));
        gotoPreviousStep();
      });
  };

  switch (step) {
    case STEPS.country:
      pageComponent = (
        <SelectCountryPage
          countries={countries}
          onSubmit={(countryItem) => {
            setWizardDataValue("country", countryItem);
            gotoNextStep(step, { country: countryItem });
          }}
        />
      );
      break;

    case STEPS.age:
      pageComponent = (
        <AgeVerificationPage
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(birthdate) => {
            setWizardDataValue("birthdate", birthdate);
            gotoNextStep(step, { birthdate: birthdate });
          }}
        />
      );
      break;

    case STEPS.postal:
      pageComponent = (
        <PostalCodePage
          country={wizardData.country}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(postalCode) => {
            setWizardDataValue("postalCode", postalCode);
            gotoNextStep(step, { postalCode: postalCode });
          }}
        />
      );
      break;

    case STEPS.postalnoresults:
      pageComponent = (
        <PostalCodeNoResultsPage
          postalCode={wizardData.postalCode}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
        />
      );
      break;

    case STEPS.careproviders:
      pageComponent = (
        <CareProvidersPage
          postalCode={wizardData.postalCode}
          careProviders={careProviders}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(careProvider) => {
            setCareProviderAndStep(careProvider);
          }}
        />
      );
      break;

    case STEPS.careprogram:
      pageComponent = (
        <CareProgramPage
          programs={carePrograms}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(careProgram) => {
            setWizardDataValue("careProgram", careProgram);
            gotoNextStep(step, { careProgram });
          }}
        />
      );
      break;

    case STEPS.programcode:
      pageComponent = (
        <ProgramCodePage
          error={programCodeError}
          expectedCodeLength={wizardData.careProgram.expectedProgramCode.length}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(code) => {
            const expectedCode = wizardData.careProgram.expectedProgramCode;
            if (code === expectedCode) {
              setProgramCodeError(undefined);
              setWizardDataValue("programCode", code);
              gotoNextStep(step);
            } else {
              setProgramCodeError(t("corona.page.program.code.invalid"));
            }
          }}
        />
      );
      break;

    case STEPS.careprograminformation:
      pageComponent = (
        <ProgramInformationPage
          careProgram={wizardData.careProgram}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={() => {
            gotoNextStep(step);
          }}
        />
      );
      break;

    case STEPS.personaldetails:
      pageComponent = (
        <PersonalDetailsPage
          questions={wizardData.countryQuestions || []}
          countryCode={wizardData.country.id}
          values={wizardData}
          showBackButton={steps.length > 1 && !(queryProgram)}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(values) => {
            // NOTE This is using a custom hook to handle this state
            setWizardData(
              (prevState) => ({ ...prevState, ...values }),
              (newState) => {
                gotoNextStep(step, newState);
              }
            );
          }}
        />
      );
      break;

    case STEPS.accountname:
      pageComponent = (
        <AccountNamePage
          error={accountNameError}
          values={wizardData}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={setAccountNameAndStep}
        />
      );
      break;

    case STEPS.organisationspecficdetails:
      pageComponent = (
        <OrganisationSpecificDetailsPage
          questions={wizardData.programQuestions || []}
          values={wizardData}
          showBackButton={steps.length > 1}
          onBackButtonClick={gotoPreviousStep}
          onSubmit={(values) => {
            setWizardData({ ...wizardData, ...values });
            gotoNextStep(step);
          }}
        />
      );
      break;

    case STEPS.creatingaccount:
      pageComponent = (
        <CreatingAccountPage onRegisterAccount={handleOnRegister} />
      );
      break;

    case STEPS.creatingaccounterror:
      pageComponent = (
        <CreatingAccountErrorPage
          email={wizardData.email}
          username={wizardData.accountName}
        />
      );
      break;

    case STEPS.completed:
      pageComponent = (
        <CompletedPage
          email={wizardData.email}
          username={wizardData.accountName}
        />
      );
      break;
  }
  // endregion

  return <div className={"c-onboard"}>{pageComponent}</div>;
};

export default Onboard;
