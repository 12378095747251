import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Page from 'components/page/Page';
import PageAnimation from 'assets/lottie/onboarrding-not-found';

/**
 * Postal code no results page
 *
 * @param {string} postalCode
 * @param {function} onBackButtonClick
 * @return {*}
 * @constructor
 */
const PostalCodeNoResultsPage = (
  {
    postalCode,
    onBackButtonClick,
  }) => {
  // translation helper.
  const {t} = useTranslation();

  return (
    <Page
      image={PageAnimation}
      title={t('corona.page.postal.code.no.results.title')}
      onBackButtonClick={onBackButtonClick}
    >
      <p>
        {t('corona.page.postal.code.no.results.description', {postalCode: postalCode})}
      </p>
    </Page>
  );
};

PostalCodeNoResultsPage.propTypes = {
  postalCode       : PropTypes.string,
  onBackButtonClick: PropTypes.func,
};

export default PostalCodeNoResultsPage;